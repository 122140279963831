const style={
    container:{
        height:"-webkit-fill-available",
        margin:"1em 0",
        justifyContent:"space-between"
    },
    header:{
        alignItems:{xs:"center",md:"end"}
    },
    header_ar:{
        alignItems:{xs:"center",md:"flex-start"}
    },
    header_logo:{
        width:"7em"
    },
    body:{
        "h1":{
           fontSize: {xs:"2.5rem",sm:"4rem",md:"6rem"}
        },
        
        justifyContent:"space-between",
        alignItems:{xs:"center",md:"start"}
    },
    body_ar:{
        textAlign: "right",
        alignItems:{xs:"center",md:"flex-end"},
     },
    go_to_home:{
        marginTop:"2em",
        width:"fit-content",
    },
    go_to_home_link:{
        "a":{
            fontSize:{xs:"0.75rem",md:".85rem"}
        },
        color:"grey",
        textDecoration:"none"
    },
    footer:{
        margin:{xs:"1em",l:"0"},
        justifyContent:"space-between"
    },
    footer_ar:{
        textAlign: "right",
        alignItems:{xs:"center",md:"flex-end"},
     },
    footer_answers:{
        flexDirection:"row",
        flexWrap:{xs:"wrap",md:"nowrap"},
        justifyContent:"space-between",
        gap:"1em"
    },
    footer_answers_ar:{
        flexDirection:"row-reverse",
        textAlign: "right",
        alignItems:{xs:"center",md:"flex-end"},
     },
    
};

export default style;