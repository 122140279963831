const MenuStyles=(visible)=>{
    return {
    drawer:{
        "& .MuiDrawer-paperAnchorBottom":{
            backgroundColor:"rgba(0,0,0,0)"
        }
    },
    menu:{
        width: "100%",
        height: "fit-content",
        zIndex: 20,
        padding: ".5em 1em",
        boxSizing:"border-box",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems:"center",
        position: "sticky",
        top:0,
        "hr":{
            borderTop: "2px solid primary.main",
            width: "1.5em",
            marginTop: "1.5em",
        },
        ...(visible&&
            {
                flexDirection: "column",
                justifyContent: "start",
                alignItems:"start",
                backgroundColor: "background.paper",
                borderRadius: "2em 2em 0 0",
            }),
        gridArea: "menu"

    },
    menu__image_logo:{
        cursor: "pointer",
        width: {xs:"6em",md:"7em"},
        height: "fit-content",
    },
    menu__options:{
        display: {xs:"none",md:"flex"},
        flexDirection: {xs:"column",md:"row"},
        justifyContent: "space-between",
        gap: ".5em",
        "li":{
            opacity:".5",
            listStyleType: "none"
        },
        "li.selected,li:hover":{
            opacity:"1",
        },
        "a":{
            color:"text.secondary",
            textTransform: "capitalize",
            textDecoration: "none",
        },
        ...(visible&&
            {
               display:"flex"
            })

    },
    menu__mobile_icon:{
        display:{xs:"inherit",md:"none"},
        ...(visible&&
            {
               display:"none"
            })
    }
}
};

export default MenuStyles;
