import { LOCALES, getLocale } from "../i18n";
export const initiateLocale = localStorage.getItem('lang')?new Intl.Locale(localStorage.getItem('lang')):getLocale(navigator.languages)
export const LocaleActions = {
  "UPDATE_LOCALE": "UPDATE",
}
export const getLocaleDirection=(local=initiateLocale)=> local.baseName === LOCALES.ARABIC.baseName ? "rtl" : "ltl"

export const LocaleReducer = (prevLocale=initiateLocale, action) => {

  if (action.type === LocaleActions.UPDATE_LOCALE) {
    switch (action.lang) {
      case 'ar': {
        localStorage.setItem('lang', LOCALES.ARABIC);
        return LOCALES.ARABIC;
      };
      case 'fr': {
        localStorage.setItem('lang', LOCALES.FRENCH);
        return LOCALES.FRENCH;
      };
      case 'en': {
        localStorage.setItem('lang', LOCALES.ENGLISH);
        return LOCALES.ENGLISH;
      };
    }
  }


  return prevLocale;
};

//  const [brightness, brightnessDispatch] = useReducer(BrightnessReducer,[]);
//brightnessDispatch({"type":BrightnessActions.UPDATE_BRIGHTNESS})
