import { Fragment } from "react"
import { IntlProvider } from "react-intl"
import { LOCALES } from "./Locales"
import messages from "./messages"

const Provider=({children,locale=LOCALES.ENGLISH})=>{
    return <IntlProvider 
        locale={locale}
        textComponent={Fragment}

        messages={flattenMessages(messages[locale])}>
        {children}
        </IntlProvider>

}
export const flattenMessages = ((nestedMessages, prefix = '') => {
    if (nestedMessages === null) {
      return {}
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
      const value       = nestedMessages[key]
      const prefixedKey = prefix ? `${prefix}.${key}` : key

      if (typeof value === 'string') {
        Object.assign(messages, { [prefixedKey]: value })
      } else {
        Object.assign(messages, flattenMessages(value, prefixedKey))
      }
      return messages
    }, {})
  })
export default Provider;