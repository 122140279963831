export const BrightnessActions={
    "UPDATE_BRIGHTNESS":"UPDATE",
}
export const modes=['light','dark','auto']
export const BrightnessReducer = (prevBrightness, action) => {
        let newMode=null;
    if (action.type === BrightnessActions.UPDATE_BRIGHTNESS) {
        if(action.mode){
        newMode=action.mode;
        localStorage.setItem('mode','auto')
        return {'mode':'auto','preferable':action.mode}

        }else {
        newMode=modes[modes.indexOf(prevBrightness.mode)+1<modes.length?modes.indexOf(prevBrightness.mode)+1:0]
        localStorage.setItem('mode',newMode)
        return {'mode':newMode,preferable:prevBrightness.preferable}
        }
    }
    return prevBrightness;
  };

//  const [brightness, brightnessDispatch] = useReducer(BrightnessReducer,[]);
//brightnessDispatch({"type":BrightnessActions.UPDATE_BRIGHTNESS})
          