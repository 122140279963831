// function([string1, string2],target id,[color1,color2])
let consoleInterval,underscoreInterval;    
export function consoleText(words, id) {
  var visible = true;
  var con = document.getElementById('profile__console');
  var letterCount = 1;
  var x = 1;
  var waiting = false;
  var target = document.getElementById(id)
  consoleInterval= window.setInterval(function() {

    if (letterCount === 0 && waiting === false) {
      waiting = true;
      target.innerHTML = words[0].substring(0, letterCount)
      window.setTimeout(function() {

        var usedWord = words.shift();
        words.push(usedWord);
        x = 1;
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (letterCount === words[0].length + 1 && waiting === false) {
      waiting = true;
      window.setTimeout(function() {
        x = -1;
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (waiting === false) {
      target.innerHTML = words[0].substring(0, letterCount)
      letterCount += x;
    }
  }, 120)
  underscoreInterval=window.setInterval(function() {
    if (visible === true) {
      con.className = 'profile__console hidden'
      visible = false;

    } else {
      con.className = 'profile__console'

      visible = true;
    }
  }, 400)
}

export function terminateConsole() {
  clearInterval(consoleInterval);
  clearInterval(underscoreInterval);
}
