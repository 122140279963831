import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import styles from './IntroductionStyles.js';

function Introduction({direction}){
    return (
        <Stack sx={styles.home__introduction} className="home__introduction container" dir={direction}>
            <Typography variant="h6" className="home__introduction_title animation">
                <FormattedMessage id="introduction.title"/>
            </Typography>
            <Typography variant="p" className="home__introduction_desc animation">
                <FormattedMessage id="introduction.paragraph"/>
            </Typography>
            <Typography variant="h5" className="home__introduction_name animation">
                <FormattedMessage id="introduction.name"/>
            </Typography>
        </Stack>
    )
}

export default Introduction;