import ReactPixel from 'react-facebook-pixel';
if (process.env.NODE_ENV === "production"){
const advancedMatching = {}// em: 'contact@kodknight.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init(process.env.REACT_APP_PIXEL_ID, advancedMatching, options);
}

const FBPixel=ReactPixel;

export default FBPixel;