import { Box, SwipeableDrawer } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
import Logo from "../../assets/images/logo.webp";
import whiteLogo from "../../assets/images/white-logo.webp";
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Styles from "./MenuStyles.js";
import { LocaleContext, ModeContext } from "../../App";

const Menu = ({ direction }) => {



  const scrollTo = () => {
    switch (window.location.pathname) {
      case "/": document.getElementById("home").scrollIntoView({ behavior: "smooth" }); break;
      case "/services": document.getElementById("services").scrollIntoView({ behavior: "smooth" }); break;
      case "/contact": document.getElementById("contact").scrollIntoView({ behavior: "smooth" }); break;
    }
  }
  const onMenuItemClick = e => {
    if (process.env.NODE_ENV === "production") {

      ReactGA.event({
        category: "menu",
        action: "click",
        label: e.currentTarget.getAttribute("data-name"),
      });
    }
    scrollTo()
  };

  useEffect(() => {
    scrollTo()
    window.addEventListener('scroll', updateTitle, { passive: true });
  }, [])


  const updateTitle = () => {
    if (isElementInViewport(document.getElementById("contact"))) {
      setSelectedItem("contact");
      document.title = intl.formatMessage({ id: "metadata.contact_title" })
    } else if (isElementInViewport(document.getElementById("services"))) {
      setSelectedItem("services");
      document.title = intl.formatMessage({ id: "metadata.services_title" })
    }
    else if (isElementInViewport(document.getElementById("home"))) {
      setSelectedItem("home");
      document.title = intl.formatMessage({ id: "metadata.title" })
    }
  };

  function isElementInViewport(el) {

    var rect = el.getBoundingClientRect();

    return (
      rect.top <= (window.outerHeight || document.documentElement.clientHeight)
    );
  }


  const intl = useIntl()

  const [visible, setVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState('contact')
  const localeContext = useContext(LocaleContext)

  const modeContext = useContext(ModeContext)

  const anchor = "bottom"
  let styles = Styles(visible)


  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setVisible(open)
  };
  useEffect(() => {
    document.querySelector("meta[name=description]").content = intl.formatMessage({ id: "metadata.description" })
    document.querySelector("meta[property='og:description']").content = intl.formatMessage({ id: "metadata.description" })
    updateTitle()
  }, [localeContext.locale])
  const menu = () => {
    return (
      <Box component="div" sx={styles.menu} >
        {visible && <hr />}
        <Link aria-label="Home" to="/" onClick={(e) =>
          document.getElementById("home").scrollIntoView({ behavior: "smooth" })}>
          <Box component="img" alt="KodKnight's Logo" src={modeContext.brightness.mode === 'light' || (modeContext.brightness.mode === 'auto' && modeContext.brightness.preferable === "light") ? Logo : whiteLogo} sx={styles.menu__image_logo} data-name="home" />
        </Link>
        <Box component="ul" sx={{ ...styles.menu__options, ...(direction === "rtl" && { "alignSelf": "end" }) }} dir={direction}>
          <Box component="li" sx={styles.menu__options_item} className={selectedItem === "home" && "selected"} onClick={onMenuItemClick} data-name="home">
            <Link aria-label="Home" className="menu-item" to="/">
              <FormattedMessage id="menu.home" />
            </Link>
          </Box>
          <Box component="li" sx={styles.menu__options_item} className={selectedItem === "services" && "selected"} onClick={onMenuItemClick} data-name="services">
            <Link className="menu-item" aria-label="Services" to="/services">
              <FormattedMessage id="menu.services" />
            </Link>
          </Box>
          <Box component="li" sx={styles.menu__options_item} className={selectedItem === "contact" && "selected"} onClick={onMenuItemClick} data-name="contact">
            <Link className="menu-item" aria-label="Contact" to="/contact">
              <FormattedMessage id="menu.contact" />
            </Link>
          </Box>
        </Box>
        {/* <MenuRoundedIcon sx={styles.menu__mobile_icon} onClick={()=>setVisible(!visible)}/>*/}
        <MenuRoundedIcon sx={styles.menu__mobile_icon} onClick={toggleDrawer("bottom", true)} />

      </Box>
    )
  }
  return (
    <>
      {visible ?
        <SwipeableDrawer
          sx={styles.drawer}
          anchor={anchor}
          open={visible}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {menu()}
        </SwipeableDrawer> : menu()}
    </>
  )
}

export default Menu;
