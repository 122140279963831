import { Box, createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import Footer from './components/footer/Footer';
import Menu from './components/menu/Menu';
import Profile from './components/profile/Profile';
import Home from './containers/home/Home';
import { I18nProvider } from './i18n';
import Contact from './components/contact/Contact';
import WorkFlow from './components/workflow/WorkFlow';
import Services from './components/Services/Services';
import ReactPixel from 'react-facebook-pixel';

import styles from "./AppStyles.js";
import DefaultTheme from "./themes/DefaultTheme";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import { createContext, useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { BrightnessActions, BrightnessReducer } from './reducers/BrightnessReducer';
import { getLocaleDirection, initiateLocale, LocaleReducer } from './reducers/LocaleReducer';
import ReactGA from "react-ga4";
import NotFound from './containers/NotFound';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import FBPixel from './analytics/FBPixel.js';
export const ModeContext = createContext();
export const LocaleContext = createContext();

export default function App(props) {

  const preferableMode = useMediaQuery('(prefers-color-scheme: dark)', { noSsr: true }) ? 'dark' : 'light';
  const [brightness, brightnessDispatch] = useReducer(BrightnessReducer, { 'mode': localStorage.getItem('mode'), 'preferable': preferableMode });
  const [locale, localeDispatch] = useReducer(LocaleReducer, initiateLocale);
  const direction = getLocaleDirection(locale)
  const defaultTheme = DefaultTheme(brightness, direction)
  const homeRef = useRef()
  const servicesRef = useRef()
  const contactRef = useRef()
  let preferableModeMemo = useCallback(
    () => {
      if (brightness.mode === 'auto' || brightness.mode === null) {
        brightnessDispatch({ "type": BrightnessActions.UPDATE_BRIGHTNESS, "mode": preferableMode });
      }
    },
    [preferableMode],
  );
  useEffect(() => {
    preferableModeMemo()
  }, [preferableMode])

  let theme = useMemo(
    () => {
      document.documentElement.setAttribute("lang", locale.language);

      return createTheme(defaultTheme, {
        ...defaultTheme,
        palette: {
          ...defaultTheme.palette,
          mode: brightness.mode,
        },
      })
    },
    [brightness, locale],
  );



  const onMenuItemClick = useCallback(e => {
    if (process.env.NODE_ENV === "production") {
      ReactGA.event({
        category: "menu",
        action: "click",
        label: e.currentTarget.getAttribute("data-name"),
      });
    }
    // scrollTo()
  }, []);

  useEffect(() => {
    const the_animation = document.querySelectorAll('.animation, .right-animation')
    
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('scroll-animation')
                
            }
            else {

                entry.target.classList.remove('scroll-animation')
            }

        })
    },
        {
            threshold: 0.1
        });
    for (let i = 0; i < the_animation.length; i++) {
        const elements = the_animation[i];

        observer.observe(elements);
    }
},[locale])
useEffect(() => {
  const the_animation = document.querySelectorAll('.animation, .right-animation')
  
  const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
          if (entry.isIntersecting) {
              entry.target.classList.add('scroll-animation')
              
          }
          else {

              entry.target.classList.remove('scroll-animation')
          }

      })
  },
      {
          threshold: 0.1
      });
  //
  for (let i = 0; i < the_animation.length; i++) {
      const elements = the_animation[i];

      observer.observe(elements);
  }
})
  useEffect(() => {
    // scrollTo()

    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

      FBPixel.pageView();

    }

    window.addEventListener('scroll', handleScroll, { passive: true });
  }, [])


  const handleScroll = (event) => {
    if (isElementInViewport(contactRef.current)) {
      document.title = "KodKnight | Contact"
    } else if (isElementInViewport(servicesRef.current)) {
      document.title = "KodKnight | Services"
    }
    else if (isElementInViewport(homeRef.current)) {
      document.title = "KodKnight | Full-Stack Developer"
    }
  };

  function isElementInViewport(el) {

    var rect = el.getBoundingClientRect();

    return (
      rect.top <= (window.outerHeight || document.documentElement.clientHeight)
    );
  }
  const Root = ({}) => {
    return (<Box sx={styles.app_container}>
      <Menu direction={direction} onMenuItemClick={onMenuItemClick} />
      <Profile direction={direction} />
      <Home direction={direction} ref={homeRef} />
      <WorkFlow direction={direction} />
      <Services direction={direction} ref={servicesRef} />
      <Contact direction={direction} ref={contactRef} />
      <Footer />
    </Box>)}
    
  const router = createBrowserRouter([
    {
      path: "/",
      element:<Root />,
      errorElement: < NotFound />,
    },{
      path: "/services",
      element:<Root />,
      errorElement: < NotFound />,
    },{
      path: "/contact",
      element:<Root />,
      errorElement: < NotFound />,
    },
    
  ]);
return (
  <I18nProvider locale={locale}>

    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <GlobalStyles styles={styles.global} />
      <ModeContext.Provider value={{ brightness, brightnessDispatch }}>
        <LocaleContext.Provider value={{ locale, localeDispatch }}>
          <RouterProvider router={router} />
        </LocaleContext.Provider>
      </ModeContext.Provider>
    </ThemeProvider>
  </I18nProvider>
);


}


