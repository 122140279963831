import { Step, stepConnectorClasses,StepConnector, StepLabel, Stepper, Typography,Stack } from '@mui/material';
import {styled } from '@mui/system'
import React from 'react'
import { FormattedMessage } from 'react-intl';
import styles from "./WorkFlowStyles.js";
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';



function RTL({direction,children}) {
  // Create rtl cache
  const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: direction==='rtl'?[rtlPlugin]:[],
});
  return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;
}
export default function WorkFlow({direction}) {
  /*const jss = create({ plugins: [...jssPreset().plugins, ltl()] });
  const generateClassName = createGenerateClassName();*/
    const steps = [ 
      <FormattedMessage id="workflow.steps.0"/>,
      <FormattedMessage id="workflow.steps.1"/>,
      <FormattedMessage id="workflow.steps.2"/>,
      <FormattedMessage id="workflow.steps.3"/>,
      <FormattedMessage id="workflow.steps.4"/>];
      const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            color:theme.palette.text.primary,
            top: 25,
        },
        [`& .${stepConnectorClasses.line}`]: {
            color:'text.primary',
            height: 1,
            border: 0,
            backgroundColor:theme.palette.text.primary,
            borderRadius: 1,
        },
      }));
      const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
        zIndex: 1,
        color: theme.palette.text.primary,
        width: 50,
        height: 50,
        display: 'flex',
        border:`1px solid ${theme.palette.text.primary}`,
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
      }));

      function ColorlibStepIcon(props) {
        const { active, completed, className } = props;
      
        const icons = {
          1: "01",
          2: "02",
          3: "03",
          4: "04",
          5: "05",
        };
      
        return (
          <ColorlibStepIconRoot ownerState={{ completed, active }}  className={className}>
            {icons[String(props.icon)]}
          </ColorlibStepIconRoot>
        );
      }
    return (
        <Stack sx={styles.workflow} dir={direction}>
            <Typography variant="h6" sx={styles.workflow__title}>
                    <FormattedMessage id="workflow.title"/>
            </Typography>
            <RTL direction={direction}>
              <Stepper sx={styles.workflow__stepper}  alternativeLabel activeStep={steps.length} connector={<ColorlibConnector />}>
              {steps.map((label) => (
                  <Step key={label} >
                  <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                  </Step>
              ))}
              </Stepper>
              </RTL>
        </Stack>
    )
}
