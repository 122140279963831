const ContactStyles={
    contact:{
        padding:{xs:"2em",md:"3em",xl:"10em"},
        //backgroundColor:"#EDEDED",
        backgroundColor:"background.paper",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        gridArea:"contact",
        borderTop:"1px solid",
        borderTopColor:"background.default"
    },
    contact__inputs:{
        gap:2,
        ".bounce":{
            outline: 0,
            backgroundColor: "background.error",
            animationName: "bounce",
            animationDuration: ".5s",
            animationDelay: "0.25s",
          },
          
          "@keyframes bounce": {
            "0%": {
              transform: "translateX(0px)",
              timingFunction: "ease-in",
            },
            "37%" :{
              transform: "translateX(5px)",
              timingFunction: "ease-out",
            },
            "55%" :{
              transform: "translateX(-5px)",
              timingFunction: "ease-in",
            },
            "73%" :{
              transform: "translateX(4px)",
              timingFunction: "ease-out",
            },
            "82%" :{
              transform: "translateX(-4px)",
              timingFunction: "ease-in",
            },
            "91%" :{
              transform: "translateX(2px)",
              timingFunction: "ease-out",
            },
            "96%" :{
              transform: "translateX(-2px)",
              timingFunction: "ease-in",
            },
            "100%" :{
              transform: "translateX(0px)",
              timingFunction: "ease-in",
            }
          }
    },
    contact__info:{
        gap:2,
        flexDirection:{xs:"column",md:"row"}
    },
    contact__message:{
        resize: "none"
    },
    
    
};

export default ContactStyles;