import { Height } from "@mui/icons-material";
import { createTheme } from "@mui/material";
import { position } from "stylis";

const defaultTheme = (brightness, direction) => {
  return createTheme({
    typography: {
      fontFamily:
        [],
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            "h6": {
              fontFamily: direction === "rtl" ? ["Changa", "sans-serif"].join(',') : ["changa", "sans-serif"].join(','),
            },
            "a": {
              textDecoration: "none",
              color: "inherit"
            },
            "a.menu-item": {
              fontFamily: direction === "rtl" ? ["Kufam", "sans-serif"].join(',') : ["Lobster", "sans-serif"].join(','),
            },
            "h5": {
              fontFamily: direction === "rtl" ? ["Noto Nastaliq Urdu", "serif"].join(',') : ["Waterfall", "sans-serif"].join(','),
            },
            "h4": {
              fontFamily: direction === "rtl" ? ["Readex Pro", "sans-serif"].join(',') : ["Titillium Web", "sans-serif"].join(','),
              fontWeight: "bold"
            },
            "h1": {
              fontFamily: direction === "rtl" ? ["janna", "sans-serif"].join(',') : ["Titillium Web", "sans-serif"].join(','),
              fontWeight: "bold"
            },
            "span, p,textarea, input,button": {
              fontFamily: direction === "rtl" ? ["Readex Pro", "sans-serif"].join(',') : ["Titillium Web", "sans-serif"].join(','),
            },
            ".hidden": {
              opacity: 0
            },
            ".animation, .right-animation": {
              opacity:0,

              transition: "all 0.7s ease-out",
              transitionDelay: "0.2s",
            },
            ".scroll-animation": {
              opacity:1,

            },
            "@media screen and (min-width:380px)":{
              ".animation , .right-animation": {
                opacity:0,
                transform: "translateX(-300px)"},
              ".scroll-animation": {
                opacity:1,
                transform: "translateX(0)",
              }},
              "@media screen and (min-width:1020px)":{
                ".right-animation": {
                  transform: "translateX(300px)",
                },
                ".scroll-animation": {
                  opacity:1,
                  transform: "translateX(0)",
                },
              }
          },
        },
      },
    },

    palette: {
      ...((brightness.mode === "dark" || (brightness.mode === "auto" && brightness.preferable === "dark")) ? {

        primary: {
          light: '#5c5c5c',
          main: '#333333',
          dark: '#0c0c0c',
          contrastText: '#FAFAFA',
        },
        secondary: {
          main: '#545454',
          contrastText: '#EBEBEB',
        },
        text: {
          primary: "#FAFAFA",
          secondary: "#EBEBEB"
        },
        background: {
          default: "#545454",
          paper: "#474747",
          workflow: "#949494",
          error: "#EF9A9A22"
        }
      } :
        {
          primary: {
            light: '#808080',
            main: '#6E6E6E',
            dark: '#2b2b2b',
            contrastText: '#FAFAFA',
          },
          secondary: {
            main: '#545454',
            contrastText: '#EBEBEB',
          },
          text: {
            primary: "#474747",
            secondary: "#616161"
          },
          background: {
            default: "#FAFAFA",
            paper: "#EDEDED",
            workflow: "#D4D4D4",
          }
        })
    },
  }


  )
};
export default defaultTheme
