import { position } from "stylis";

let ServiceStyles=(odd)=>{
return {
    services:{
        color:"text.secondary",
        backgroundColor:"background.paper",
        display:"grid",
        gap:{xs:"2em",lg:"unset"},
        padding: "1em",
        gridArea: "services"
    },
    service:{
        height:{xs:"fit-content",lg:"20em"},
        flexDirection:odd?{md:"row-reverse"}:{md:"row"},
        alignItems:"center",
        justifyContent:"space-around",
        margin:"1em",
        rowGap:{xs:"2em"},
    },

    service__image:{
        flex:1,
        maxWidth:"20em",
        translate:"10px",
        animationName: "right-to-left",
        animationDuration: "4s", 
    },
    service__info:{
        maxWidth:"20em",
        flex:1,
        alignItems:"center",
        justifyContent:"center",
        translate:"-10px",
        animationName: "left-to-right",
        animationDuration: "4s", 
    },
    service__options:{
        alignItems:"center",
        justifyContent:"center",
        ".service:not(.service:last-child)::after":{
            content: '""',
            height:"2em",
            position:"absolute",
            borderRight: "2px solid darkgrey"
        },
        
    },
    service__option:{
        backgroundColor:"background.default",
        flexDirection:"row",
        alignItems:"center",
        justifyContent:"center",
        padding:".6em 1.6em",
        borderRadius:"2em",
        gap:"2em"

    },
    service__option_image:{
        cursor:"help",
        height:"2em",
    },
    
}
};

export default ServiceStyles;