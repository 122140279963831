import { LOCALES } from "../Locales";

export default {
    [LOCALES.ENGLISH]: {
        menu: {
            home: "Home",
            services: "Services",
            contact: "Contact"
        },
        profile: {
            texts: ["KodKnight", "Full stack Developer", "Mahdi Rmila"]
        },
        introduction: {
            title: "Full-stack Developer",
            paragraph: "I design and develop services for customers of all sizes, specializing in creating modern websites, web services and Dapps.",
            name: "Mahdi Rmila"
        },
        biography: "I am an experienced full-stack web developer. I code and create web elements for people around the world who are changing the world with their brillant ideas. That's why I make sure that the final product will meet their requirements and will be delivered in time.",
        services: [
            {
                title: "Design",
                paragraph: "Clean, modern designs - optimized for performance, search engines, and converting users to customers.",
            },
            {
                title: "Website Audits",
                paragraph: "Looking to improve your page performance, SEO, or user experience? Request a free site audit.",
            },
            {
                title: "Mobile-friendly",
                paragraph: "A responsive design makes your website accessible to all users, regardless of their device.",
            },
            {
                title: "Front-end Developer",
                paragraph: "Looking for a developer who likes to bring ideas to life in the browser and give users a good experience (SEO Friendly websites, optimized images for faster loading).",
                list: {
                    title: "Technologies",
                    items: ["Java Script", "React.js", "Html"]
                }
            },
            {
                title: "Back-end Developer",
                paragraph: "Looking for a developer to build an API (Rest, GraphQL) or to work on behind-the-scenes activities who assure a good communication experience between the client and the database.",
                list: {
                    title: "Technologies",
                    items: ["Java", "Node.js"]
                }
            }
        ],
        workflow: {
            title: "our work process",
            steps: ["conception", "plan", "design", "development", "quality check"]
        },
        contact: {
            title: "Contact me",
            paragraph: "I’m interested in freelance opportunities – especially ambitious or large projects.However, if you have other request or question, don’t hesitate to use the form.",
            name: "name",
            email: "email",
            subject: "subject",
            message: "message",
            button: "send"
        },
        copyright: ", All Rights Reserved",
        metadata: {
            description: "I design and develop services for customers of all sizes, specializing in creating modern websites, web services and Dapps.",
            title: "KodKnight | Full-Stack Developer",
            contact_title: "KodKnight | Contact",
            services_title: "KodKnight | Services",
        },
        errors: {
            broke_message: { header: "whoops", body: "Something broke." },
            go_to_homepage: "GOTO HOMEPAGE",
            "/404": {
                message: "Error 404 - PAGE_NOT_FOUND",
                info: {
                    question: "What causes broken links on a website?",
                    answers: [
                        {
                            header: "Typos",
                            body: "A type is a common human error that can occur when words within the link URL are misspelled.",
                        },
                        {
                            header: "A Page Has Been Deleted",
                            body: "If you have a valid link going to a page but then that linking page is deleted, the link doesn’t have anywhere to direct to and an error message will display.",
                        },
                        {
                            header: "Broken Code",
                            body: "Code itself can also become broken, whether it is HTML, CSS, Javascript, or other plugins. When the code is broken and needs to be fixed the links won’t work and will instead show errors, so keeping an eye on code is essential.",
                        },
                    ],
                },
            },
            "/500": {
                message: "Error 500 - INTERNAL_SERVER_ERROR",
                info: {
                    question: "What causes a 500 Internal Server error?",
                    answers: [
                        {
                            header: "",
                            body: "A server error can be caused by any number of things from uploading the incorrect file to as bug in a piece of code. This error response is a generic 'catch-all' response. The web server is telling you that something went wrong, but it's not sure exactly what that is.",
                        },
                    ],
                },
            },
            "/401": {
                message: "Error 401 - ACCESS_DENIED",
                info: {
                    question: "What Causes a 401 Error?",
                    answers: [
                        {
                            header: "",
                            body: "These errors occur on websites that require a login in order to access them. In most cases, it means that something is either wrong with the credentials or with the browser’s ability to read them as valid.",
                        }
                    ],
                },
            },
            "/301": {
                message: "Error 301 - ACCESS_FORBIDDEN",
                info: {
                    question: "What Causes a 301 Error?",
                    answers: [
                        {
                            header: "",
                            body: "You might encounter the 301 forbidden error when you visit an empty website directory or a specific page that has a permission error. This is because most websites are set up to disallow directory browsing to prevent attackers from accessing sensitive files.",
                        }
                    ],
                },
            },
            "/000": {
                message: "UNKNOWN_ERROR_OCCURED",
                info: {
                    question: "What Causes a Unknown Error?",
                    answers: [
                        {
                            header: "",
                            body: "An unknown error occurred is a very common yet rare error. As the error may occur due to almost any reason.",
                        }
                    ],
                },
            },
            "blog": {
                menu:{
                    logo_alt:"KodKnight LOGO",
                    best_practice:"best practice",
                    blogs:"blogs",
                    Database_knowledge:"database knowledge",
                    search:"search"
                }
            }
            ,
        }
    }
}