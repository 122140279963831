import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import Service from './Service'
import service_1 from "../../assets/images/service01.webp";
import service_2 from "../../assets/images/service2.webp";
import service_3 from "../../assets/images/service3.webp";
import service_4 from "../../assets/images/service4.webp";
import service_0 from "../../assets/images/service0.webp";
import javascript from "../../assets/images/javascript.webp";
import java from "../../assets/images/java.webp";
import nodejs from "../../assets/images/nodejs.webp";
import reactjs from "../../assets/images/reactjs.webp";
import html from "../../assets/images/html.webp";

import { useIntl } from 'react-intl';
import Styles from "./ServiceStyles.js";
import { forwardRef } from 'react';

const Services = forwardRef(({ direction }, ref) => {
    const intl = useIntl()
    let styles = Styles(0)
    const services = [
        {
            image: service_0,
            alt: "Design",
            title: intl.formatMessage({ id: "services.0.title" }),
            paragraph: intl.formatMessage({ id: "services.0.paragraph" }),
        },
        {
            image: service_1,
            alt: "Website Audits",
            title: intl.formatMessage({ id: "services.1.title" }),
            paragraph: intl.formatMessage({ id: "services.1.paragraph" }),
        },
        {
            image: service_2,
            alt: "Mobile-friendly",
            title: intl.formatMessage({ id: "services.2.title" }),
            paragraph: intl.formatMessage({ id: "services.2.paragraph" }),
        }, {
            image: service_3,
            alt: "Front-end Developer",
            title: intl.formatMessage({ id: "services.3.title" }),
            paragraph: intl.formatMessage({ id: "services.3.paragraph" }),
            list: {
                title: intl.formatMessage({ id: "services.3.list.title" }),
                items: [{
                    name: intl.formatMessage({ id: "services.3.list.items.0" }),
                    image: javascript
                },
                {
                    name: intl.formatMessage({ id: "services.3.list.items.1" }),
                    image: reactjs
                },
                {
                    name: intl.formatMessage({ id: "services.3.list.items.2" }),
                    image: html
                }
                ]
            }
        }, {
            image: service_4,
            alt: "Back-end Developer",
            title: intl.formatMessage({ id: "services.4.title" }),
            paragraph: intl.formatMessage({ id: "services.4.paragraph" }),
            list: {
                title: intl.formatMessage({ id: "services.4.list.title" }),
                items: [{
                    name: intl.formatMessage({ id: "services.4.list.items.0" }),
                    image: java
                },
                {
                    name: intl.formatMessage({ id: "services.4.list.items.1" }),
                    image: nodejs
                }
                ]
            }
        }
    ]

    return (
        <Box id="services" sx={styles.services} className="container" dir={direction} ref={ref}>

            {services.map((service, index) => <Service service={service} odd={index % 2 !== 0} />)}

        </Box>
    )
});

export default Services;
/*
 const services=[];
        for (const key of Array.of(5).keys()) {
            services.push(
            {
                image:`service_${key}`,
                title:<FormattedMessage id={`services.${key}.title`}/>,
                paragraph:<FormattedMessage id={`services.${key}.paragraph`}/>,
            }
        )
        } */