const AppStyles={
    global:{
        body: {
            height: "100vh",
            margin:"auto",
            alignItems: "center",
            ".container":{
                padding: "1em",
                minHeight: "40vh",
                boxSizing:"border-box"
            },
            "#root":{
                height:"-webkit-fill-available",
            },
            ".hidden": {
                opacity:0
            },
            "@media screen and (min-width:900px)":{
                ".container":{
                    minHeight: "90vh",
                },
                    maxWidth: "80vmax",
                    padding:"1em",
                 
            }
        }
      },
    app_container:{
        display:"grid",
        alignItems:"center",
        justifyContent:"space-around",
        gridTemplateAreas: {xs:`"menu""profile""home""workflow""services""contact""footer"`,
            md:`"menu menu profile" "home home ." "workflow workflow ." "services services ." "contact contact ." "footer footer ."`},
        gridTemplateRows: "2em",
        columnGap:{md:2,lg:4}
    } 
    };
    
    export default AppStyles;
