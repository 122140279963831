import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Link, useRouteError } from "react-router-dom";

import style from "../assets/styles/ErrorStyle";

import Logo from "../assets/images/logo.webp";
import whiteLogo from "../assets/images/white-logo.webp";
import { LocaleContext, ModeContext } from "../App";
import { FormattedMessage, useIntl } from "react-intl";
import { getLocaleDirection } from "../reducers/LocaleReducer";

const NotFound = () => {
  const modeContext = useContext(ModeContext);
  const localeContext = useContext(LocaleContext);
  const dir=getLocaleDirection(localeContext.locale)
  const intl = useIntl()
  const error = useRouteError();

  console.log(error.status);
  const status= ["401","404","301","500","000"].includes(error.status)?`/${error.status}`:"/404"
  const infos = [


    {
      header: intl.formatMessage({ id: `errors.${status}.info.answers.0.header` }),
      body: intl.formatMessage({ id: `errors.${status}.info.answers.0.body` })
    },
    {
      header: intl.formatMessage({ id: `errors.${status}.info.answers.1.header` }) ,
      body: intl.formatMessage({ id: `errors.${status}.info.answers.1.body` })
    },
    {
      header: intl.formatMessage({ id: `errors.${status}.info.answers.2.header` }),
      body: intl.formatMessage({ id: `errors.${status}.info.answers.2.body` }) 
    }
  ];

  return (
    <Stack sx={style.container} className="error">
      <Stack sx={{...style.header,...(dir==="rtl"&&style.header_ar)}} fullwidth>
        <Link to="/" fullwidth>
          <Box
            component="img"
            alt="KodKnight's Logo"
            src={
              modeContext.brightness.mode === "light" ||
                (modeContext.brightness.mode === "auto" &&
                  modeContext.brightness.preferable === "light")
                ? Logo
                : whiteLogo
            }
            sx={style.header_logo}
            data-name="home"
          />
        </Link>
      </Stack>
      <Stack sx={{...style.body,...(dir==="rtl"&&style.body_ar)}}>
        <Stack>
          <Typography variant="h1"><FormattedMessage id={`errors.broke_message.header`} /></Typography>
          <Typography variant="h1"><FormattedMessage id={`errors.broke_message.body`} /></Typography>
        </Stack>
        <Typography variant="p">{<FormattedMessage id={`errors.${status}.message`} /> ? <FormattedMessage id={`errors.${status}.message`} /> : <FormattedMessage id="/404.message" />}</Typography>
        <Button variant="outlined" color="inherit" sx={style.go_to_home}>
          <Link to="/">
          <FormattedMessage id={`errors.go_to_homepage`} />
          </Link>
        </Button>
      </Stack>
      <Stack sx={{...style.footer,...(dir==="rtl"&&style.footer_ar)}}>
        <Stack>
          <Typography variant="h6">{<FormattedMessage id={`errors.${status}.info.question`} /> ? <FormattedMessage id={`errors.${status}.info.question`} /> : <FormattedMessage id="/404.info.question" />}</Typography>
          <Stack sx={{...style.footer_answers,...(dir==="rtl"&&style.footer_answers_ar)}}>
            {infos.map((answer, index) => (
              answer.body !== `errors.${status}.info.answers.${index}.body` &&
              <Stack>
                {answer.header !== `errors.${status}.info.answers.${index}.header` &&<Typography variant="h4" fontSize="large" fontWeight="bold">
                  {answer.header}
                </Typography>}
                <Typography variant="p">{answer.body}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
export default NotFound;
