import axios from 'axios' // node
export const config = {
    baseURL: process.env.REACT_APP_API,
    // timeout: 3000,
    withCredentials: true,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem("token")||""}`
    }
  }
const api = axios.create(config);

export default api;