const FooterStyles={
    footer:{
        color: "#FAFAFA",
        backgroundColor: "primary.main",
        width:"100%",
        minHeight:"7em",
        display:"grid",
        justifyContent: "space-around",
        alignItems: "center",
        gridArea:"footer"
    },
    footer__logo:{
        cursor: "pointer",
        width:{xs:"8em",md:"9em"},
        height: "fit-content",
        padding: ".5em 1em",
        gridArea: "1 / 1" 
    
    },
    footer__copy_right:{
        fontSize:{xs:".6em",md:".8em"},
        gridArea: {xs:"2 / 1 /span 1/ span 2",lg:"1 / 2 /span 1/ span 2"},
        textAlign: "center",
        "&::after":{
            content:'"."',
        }
    },
    footer__contacts:{
        width:"fit-content",
        height: "1.5em",
        gap:{xs:1,md:2},
        flexDirection:"row",
        justifyContent:"space-evenly",
        alignItems:"center",
        gridArea: {xs:"1 / 2",lg:"1 / 4"},
        "svg":{
            fill:"#FAFAFA",
            width:{xs:".7em",md:".9em"}
        }
    },
    footer__contact:{
        cursor: "pointer",
        width:"1.6em",
        height: "1.6em",
        color: "#FAFAFA",
        backgroundColor: "primary.light",
    },
    footer__language:{
        fontFamily:"changa,sans-serif",
        width:"fit-content",
        backgroundColor:"transparent",
        textTransform:"capitalize",
        gap:".5em",
        flexDirection:"column-reverse"},
        footer__language_options_visible:{
            position: "relative",
            bottom: "2.5em",
    },
    footer__language_options:{
        gap:".5em",
    }
};
export default FooterStyles;