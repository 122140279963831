
export const LOCALES ={
    ENGLISH:new Intl.Locale('en-US'),
    ARABIC:new Intl.Locale('ar-arabext'),
    FRENCH:new Intl.Locale('fr-FR')
}

export const getLocale=(_languages)=>{
      if(_languages.includes("ar")){
        return LOCALES.ARABIC
      }else if(_languages.includes("fr")){
        return LOCALES.FRENCH
      }else{
        return LOCALES.ENGLISH
      }
}

