import { refreshToken } from './AuthHandler';
import api, { config } from './api';

const sendEmail = (data) => {
    return new Promise((resolve, reject) => {
        api.post("/mail/send", data).
        then(async (sendRes) => {
            resolve(sendRes);
        }).catch(async (sendError) => {
            if (sendError.response.status == 401){
                refreshToken().then(async refreshTokenRes=>{
                    if(refreshTokenRes.data.success){
                        localStorage.setItem("token", window.btoa(unescape(encodeURIComponent(refreshTokenRes.data.token))))
                        config.headers.Authorization=`Bearer ${window.btoa(unescape(encodeURIComponent(refreshTokenRes.data.token)))}`
                        api.post("/mail/send", data,config).
                        then(reSendRes=>resolve(reSendRes)
                        ).catch(reSendErr=>{reject( reSendErr.response)})
                    }else
                    reject(refreshTokenRes)
                }).catch(refreshTokenErr=>{
                    reject( refreshTokenErr.response)
                })
                
            }else
            reject(sendError.response) 
        });
     });
   


}
export { sendEmail };