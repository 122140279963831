import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

function Biography({direction}){
    return (
        <Stack className="home__biography container" justifyContent="center" dir={direction}>
            <Typography variant="p" className="animation">
                <FormattedMessage id="biography"/>
            </Typography>
        </Stack>
    )
}

export default Biography;