import { LOCALES } from "../Locales";

export default {
    [LOCALES.FRENCH]:{
        menu:{
            home:"Accueil",
            services:"Services",
            contact:"Contact"
        },
        profile:{
            texts:["KodKnight","Développeur full-stack","Mahdi Rmila"]
        },
        introduction:{
            title:"Développeur full-stack",
            paragraph:"Je conçois et développe des services pour des clients de toutes tailles, en me spécialisant dans la création de sites web modernes, de services web et de Dapps.",
            name:"Mahdi Rmila"
        },
        biography:"Je suis un développeur web expérimenté. Je suis originaire du Maroc. Je code et crée des éléments web pour des personnes du monde entier qui changent le monde avec leurs idées brillantes. C'est pourquoi je m'assure que le produit final répondra à leurs exigences et sera livré à temps.",
        services:[
            {
                title:"Design",
                paragraph:"Des designs propres et modernes - optimisés pour les performances, les moteurs de recherche et la conversion des utilisateurs en clients.",
            },
            {
                title:"Audits de sites web",
                paragraph:"Vous cherchez à améliorer les performances de vos pages, le référencement ou l'expérience utilisateur ? Demandez un audit gratuit de votre site.",
            },
            {
                title:"Adapté aux mobiles",
                paragraph:"Un design responsive rend votre site web accessible à tous les utilisateurs, quel que soit leur appareil.",
            },
            {
                title:"Développeur Front-end",
                paragraph:"Vous recherchez un développeur qui aime donner vie aux idées dans le navigateur et offrir aux utilisateurs une bonne expérience (sites Web conviviaux pour le référencement, images optimisées pour un chargement plus rapide).",
                list:{
                    title:"Technologies",
                    items:["Java Script","React.js","Html"]
                }
            },
            {
                title:"Développeur back-end",
                paragraph:"Vous recherchez un développeur pour créer une API (Rest, GraphQL) ou pour travailler sur des activités en coulisses qui assurent une bonne expérience de communication entre le client et la base de données.",
                list:{
                    title:"Technologies",
                    items:["Java","Node.js"]
                }
            }
        ],
        workflow:{
            title:"Processus de travail",
            steps:["conception","plan","design","développement","contrôle de la qualité"]
        },
        contact:{
            title:"Contact moi",
            paragraph:"Je suis intéressé par les opportunités de travail en freelance - en particulier les projets ambitieux ou de grande envergure.Toutefois, si vous avez d'autres demandes ou questions, n'hésitez pas à utiliser le formulaire.",
            name:"nom",
            email:"email",
            subject:"sujet",
            message:"message",
            button:"envoyer"
        },
        copyright:", Tous droits réservés",
        metadata:{
            description:"Je conçois et développe des services pour des clients de toutes tailles, en me spécialisant dans la création de sites web modernes, de services web et de Dapps.",
            title:"KodKnight | Full-Stack Développeur",
            contact_title:"KodKnight | Contact",
            services_title:"KodKnight | Services",
        },
        errors:{
            broke_message: { header: "oups", body: "Quelque chose s'est cassé." },
            go_to_homepage: "ALLER À LA PAGE D'ACCUEIL",
        "/404": {
            message: "Erreur 404 - PAGE_NON_TROUVÉE",
            info: {
                question: "Qu'est-ce qui cause les liens brisés sur un site Web?",
                answers: [
                    {
                        header: "Fautes de frappe",
                        body: "Un type est une erreur humaine courante qui peut se produire lorsque des mots dans l'URL du lien sont mal orthographiés.",
                    },
                    {
                        header: "Une page a été supprimée",
                        body: "Si vous avez un lien valide vers une page mais que cette page de liaison est supprimée, le lien n'a nulle part où diriger et un message d'erreur s'affichera.",
                    },
                    {
                        header: "Code brisé",
                        body: "Le code lui-même peut également être cassé, qu'il s'agisse de HTML, CSS, Javascript ou d'autres plugins. Lorsque le code est cassé et doit être corrigé, les liens ne fonctionneront pas et afficheront des erreurs à la place, il est donc essentiel de garder un œil sur le code.",
                    },
                ],
            },
        },
        "/500": {
            message: "Erreur 500 - ERREUR_INTERNE_DU_SERVEUR",
            info: {
                question: "Qu'est-ce qui cause une erreur 500 du serveur interne?",
                answers: [
                    {
                        header: "",
                        body: "Une erreur de serveur peut être causée par un certain nombre de choses allant du téléchargement du fichier incorrect à un bogue dans un morceau de code. Cette réponse d'erreur est une réponse \"fourre-tout\" générique. Le serveur Web vous dit que quelque chose s'est mal passé, mais il ne sait pas exactement de quoi il s'agit.",
                    }
                ],
            },
        },
        "/401": {
            message: "Erreur 401 - ACCÈS_REFUSÉ",
            info: {
                question: "Qu'est-ce qui cause une erreur 401?",
                answers: [
                    {
                        header: "",
                        body: "Ces erreurs se produisent sur les sites Web qui nécessitent une connexion pour y accéder. Dans la plupart des cas, cela signifie que quelque chose ne va pas avec les informations d'identification ou avec la capacité du navigateur à les lire comme valides.",
                    }
                ],
            },
        },
        "/301": {
            message: "Erreur 301 - ACCÈS_INTERDIT",
            info: {
                question: "Qu'est-ce qui cause une erreur 301?",
                answers: [
                    {
                        header: "",
                        body: "Vous pouvez rencontrer l'erreur interdite 301 lorsque vous visitez un répertoire de site Web vide ou une page spécifique contenant une erreur d'autorisation. En effet, la plupart des sites Web sont configurés pour interdire la navigation dans les répertoires afin d'empêcher les attaquants d'accéder aux fichiers sensibles.",
                    }
                ],
            },
        },
        "/000": {
            message: "UNE_ERREUR_INCONNUE_S'EST_PRODUITE",
            info: {
                question: "Qu'est-ce qui cause une erreur inconnue?",
                answers: [
                    {
                        header: "",
                        body: "Une erreur inconnue s'est produite est une erreur très courante mais rare. Comme l'erreur peut se produire pour presque n'importe quelle raison.",
                    },
                ],
            },
        },}
    }
}