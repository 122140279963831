import { Stack } from '@mui/material'
import React, { forwardRef } from 'react'
import Biography from '../../components/biography/Biography'
import Introduction from '../../components/introduction/Introduction'
import styles from "./HomeStyles.js";



const Home = forwardRef(({direction}, ref) => {
    return (
        <Stack sx={styles.home} id="home" className="home" ref={ref}>
            <Introduction direction={direction}/>
            <Biography direction={direction}/>
        </Stack>
    )
})
export default Home;
