import { Alert, Box, Button, Snackbar, Stack, styled, Typography } from '@mui/material'
import React, { forwardRef, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { useForm } from "react-hook-form";

import styles from "./ContactStyles.js";
import { sendEmail } from '../../client/EmailHandler.js';
import ReactGA from "react-ga4";
import FBPixel from '../../analytics/FBPixel.js';

const Contact = forwardRef(({ direction }, ref) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm({ shouldUseNativeValidation: false, });
    const [alert, setAlert] = useState({})
    const [isAlertVisible, setAlertVisible] = useState(false)
    const Text = styled(Box)(({ theme }) => ({
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.default,
        border: "unset",
        padding: "1em",
        borderRadius: "5px",
        flex: 1,
    }));
    const onClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlertVisible(false)
    };
    const onSubmit = data => {
        FBPixel.track("Contact",{type:"Email"})

        sendEmail(data).then(res => {
            setAlertVisible(true);
            setAlert({ "message": res.data.message, "status": res.status })
            if (process.env.NODE_ENV === "production") {

                ReactGA.event({
                    category: "contact",
                    action: "submit",
                    label: res.data.message,
                });
            }
        }).catch(err => {
            const errorMsg = err.data.message || err.message
            setAlertVisible(true);
            setAlert({ "message": errorMsg, "status": err.status })
            if (process.env.NODE_ENV === "production") {

                ReactGA.event({
                    category: "contact",
                    action: "submit",
                    label: errorMsg,
                });
            }
        })
    }
    return (
        <Box component="form" id="contact" sx={styles.contact} dir={direction} ref={ref} onSubmit={handleSubmit(onSubmit)}>
            <Stack sx={styles.contact__inputs}>
                <Typography variant="h6" className='right-animation'>
                    <FormattedMessage id="contact.title" />
                </Typography>
                <Typography variant="p" className='animation'>
                    <FormattedMessage id="contact.paragraph" />
                </Typography>
                <Stack sx={styles.contact__info}>
                    <FormattedMessage id="contact.name">
                        {name => <Text component="Input" className={errors.name && "bounce"} placeholder={name} {...register("name", { required: "Please enter your first name." })} />}
                    </FormattedMessage>
                    <FormattedMessage id="contact.email">
                        {email => <Text component="Input" className={errors.email && "bounce"} placeholder={email} {...register("email", { required: true })} />}
                    </FormattedMessage>
                </Stack>
                <FormattedMessage id="contact.subject">
                    {subject => <Text component="input" className={errors.subject && "bounce"} placeholder={subject} {...register("subject", { required: true })} />}
                </FormattedMessage>
                <FormattedMessage id="contact.message">
                    {message => <Text component="textArea" className={errors.message && "bounce"} resize={false} rows="10" sx={styles.contact__message} placeholder={message} {...register("message", { required: true })} />}
                </FormattedMessage>

                <Button type="submit" sx={styles.contact__button} variant="contained">
                    <FormattedMessage id="contact.button" />
                </Button>
            </Stack>
            <Snackbar open={isAlertVisible}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                onClose={onClose}
                autoHideDuration={6000}>
                <Alert severity={alert.status === 200 ? "success" : "error"}>{alert.message}</Alert>
            </Snackbar>
        </Box>
    )
})
export default Contact;
