import { LOCALES } from "../Locales";

export default {
    [LOCALES.ARABIC]:{
    menu:{
        home:"الصفحة الرئيسية",
        services:"الخدمات",
        contact:"الاتصال"
    },
    profile:{
        texts:["KodKnight","مطور ويب شامل","ارميلة مهدي"]
    },
    introduction:{
        title:"مطور ويب شامل",
        paragraph:"أقوم بتصميم وتطوير خدمات للعملاء من جميع الأحجام ، متخصص في إنشاء المواقع الحديثة وخدمات ويب و التطبيقات اللامركزية.",
        name:"ارميلة مهدي"
    },
    biography:"أنا مطور ويب ذو خبرة. أقوم بتطوير المواقع الالكترونية للناس في جميع أنحاء العالم، الذين يغيرون العالم  بأفكارهم الرائعة. لهذا السبب أتأكد من أن المنتج النهائي سوف يلبي متطلباتهم وسيتم تسليمه في الوقت المناسب. ",
    services:[
        {
            title:"التصميم",
            paragraph:"تصميمات نظيفة وحديثة - مُحسَّنة للأداء ومحركات البحث وتحويل المستخدمين إلى عملاء.",
        },
        {
            title:"تدقيقات الموقع",
            paragraph:"هل تتطلع إلى تحسين أداء صفحتك أو تحسين محركات البحث أو تجربة المستخدم؟ طلب تدقيق موقع مجاني.",
        },
        {
            title:"متوافق مع الجوال",
            paragraph:"التصميم المتجاوب يجعل الموقع الالكتروني الخاص بك في متناول جميع المستخدمين، بغض النظر عن أجهزتهم.",
        },
        {
            title:"مطور الواجهة الأمامية",
            paragraph:"أحب تطوير الأشياء من البداية، والاستمتاع بجلب الأفكار إلى الحياة في المتصفح ومنح المستخدمين تجربة جيدة (مواقع الكترونية صديقة لمواقع البحث ، والصور المحسنة لتحميل أسرع...).",
            list:{
                title:"التقنيات",
                items:["Java Script","React.js","Html"]
            }
        },
        {
            title:"مطور الخلفي",
            paragraph:"تبحث عن مطور لبناء الواجهة الخلفية لموقعك (REST ،GraphQL) أو للعمل على الأنشطة وراء الكواليس لضمان تجربة اتصال جيدة بين العميل وقاعدة البيانات.",
            list:{
                title:"التقنيات",
                items:["Java","Node.js"]
            }
        }
    ],
    workflow:{
        title:"آلية العمل",
        steps:["تصور","خطة","التصميم","تطوير","فحص الجودة"]
    },
    contact:{
        title:"اتصل بي ",
        paragraph:"أنا مهتم بفرص العمل الحر - خاصة المشاريع الطموحة أو الكبيرة. ومع ذلك ، إذا كان لديك طلب أو سؤال آخر ، فلا تتردد في استخدام النموذج.",
        name:"الاسم",
        email:"البريد الإلكتروني",
        subject:"الموضوع",
        message:"الرسالة",
        button:"إرسال"
    },
    copyright:", كل الحقوق محفوظة",
    metadata:{
        description:"أقوم بتصميم وتطوير خدمات للعملاء من جميع الأحجام ، متخصص في إنشاء المواقع الحديثة وخدمات ويب و التطبيقات اللامركزية.",
        title:"KodKnight | مطور ويب شامل",
        contact_title:"KodKnight | الاتصال",
        services_title:"KodKnight | الخدمات",
    },
    errors:{
        broke_message: { header: "مع الأسف", body: ".شيء ما انكسر" },
        go_to_homepage: "انتقل إلى الصفحة الرئيسية",
    "/404": {
        message: "خطأ 404 - لم_يتم_العثور_على_الصفحة",
        info: {
            question: "ما الذي يسبب الروابط المعطلة على موقع الويب؟",
            answers: [
                {
                    header: "أخطاء الكتابة",
                    body: ".النوع هو خطأ بشري شائع يمكن أن يحدث عند وجود أخطاء إملائية في الكلمات الموجودة في عنوان URL للرابط",
                },
                {
                    header: "تم حذف صفحة",
                    body: ".إذا كان لديك رابط صالح ينتقل إلى صفحة ما ولكن تم حذف هذه الصفحة المرتبطة ، فلن يكون للرابط أي مكان للتوجيه إليه وستظهر رسالة خطأ",
                },
                {
                    header: "كود مكسور",
                    body: "يمكن أيضًا أن تصبح الشفرة نفسها معطلة ، سواء كانت HTML أو CSS أو Javascript .أو مكونات إضافية أخرى. عندما يتم كسر الشفرة وتحتاج إلى إصلاح ، لن تعمل الروابط وستعرض الأخطاء بدلاً من ذلك ، لذا فإن مراقبة الشفرة أمر ضروري",
                },
            ],
        },
    },
    "/500": {
        message: "خطأ 500 - خطأ_خادم_داخلي",
        info: {
            question: "ما الذي يسبب 500 خطأ الخادم الداخلي؟",
            answers: [
                {
                    header: "",
                    body: ".يمكن أن يحدث خطأ في الخادم بسبب أي عدد من الأشياء من تحميل الملف غير الصحيح إلى خطأ في جزء من التعليمات البرمجية. استجابة الخطأ هذه هي استجابة عامة 'شاملة'. يخبرك خادم الويب أن هناك خطأ ما ، لكنه غير متأكد بالضبط ما هو",
                }
            ],
        },
    },
    "/401": {
        message: "خطأ 401 - تم_رفض_الوصول",
        info: {
            question: "ما الذي يسبب 401 خطأ؟",
            answers: [
                {
                    header: "",
                    body: ".تحدث هذه الأخطاء على مواقع الويب التي تتطلب تسجيل دخول للوصول إليها. في معظم الحالات ، فهذا يعني أن هناك شيئا ما خطأ في بيانات الاعتماد أو مع قدرة المتصفح على قراءتها على أنها صالحة",
                }
            ],
        },
    },
    "/301": {
        message: "خطأ 301 - ممنوع_الوصول",
        info: {
            question: "ما الذي يسبب 301 خطأ؟",
            answers: [
                {
                    header: "",
                    body: ".قد تواجه الخطأ المحظور 301 عند زيارة دليل موقع ويب فارغ أو صفحة معينة بها خطأ في الإذن. وذلك لأن معظم مواقع الويب تم إعدادها لعدم السماح بتصفح الدليل لمنع المهاجمين من الوصول إلى الملفات الحساسة",
                },{
                    header: "",
                    body: "",
                },
                {
                    header: "",
                    body: "",
                 },
            ],
        },
    },
    "/000": {
        message: "حدث_خطأ_غير_معروف",
        info: {
            question: "ما الذي يسبب خطأ غير معروف؟",
            answers: [
                {
                    header: "",
                    body: ".حدث خطأ غير معروف هو خطأ شائع جدا ولكنه نادر. كما قد يحدث الخطأ بسبب أي سبب تقريبا",
                }
            ],
        },
    },}
    }
}