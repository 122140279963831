
const ProfileStyles={
    profile:{
        position: {xs:"unset",md:"sticky"},
        top: {md:"calc(50% - 8em)"},
        justifyContent:"center",
        alignItems:"center",
        gap:3,
        gridArea: "profile"
    }, 
    profile__console:{
        alignItems:"flex-end",
        justifyContent:"center",
        flexDirection:"row",
    },
    profile__image:{
        borderRadius:"50%",
        width: {xs:"15em",md:"12em",lg:"15em"},
        boxShadow: "10px 10px 2px 0px",
        filter: "drop-shadow(1px 1px 2px #474747)"
    },
    profile__text:{
        textAlign: "center"
    }
   
};

export default ProfileStyles;