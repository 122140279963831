
const WorkFlowStyles={
    workflow:{
        backgroundColor:"background.workflow",
        height:{xs:"fit-content",lg:"20em"},
        display:"flex",
        justifyContent:"space-evenly",
        alignItems:"center",
        textTransform:"capitalize",
        padding:"2em",
        gap:"2em",
        gridArea: "workflow"
    },
    workflow__stepper:{
        width:"100%",
        display:{xs:"grid",md:"flex"},
        gridTemplateColumns: {xs:"repeat(2, 1fr)",sm:"repeat(3, 1fr)",md:"flex"},
        rowGap:3,
        '.MuiStepLabel-label.Mui-completed':{
            textTransform:"capitalize"
        },
        '.MuiStepConnector-horizontal':{
            display:{xs:"none",md:"inherit"},
            left:{md:"calc(-50% + 30px)"},
            right:{md:"calc(+50% + 30px)"}
        }
    }
};

export default WorkFlowStyles;