import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import profileImage from "../../assets/images/profile.webp";
import {useIntl} from 'react-intl';
import { consoleText, terminateConsole } from "../../utils/utils";

import styles from'./ProfileStyles.js';
function Profile(){
    const intl= useIntl()
    const texts=[
        intl.formatMessage({ id: 'profile.texts.0' }),
        intl.formatMessage({ id: 'profile.texts.1' }),
        intl.formatMessage({ id: 'profile.texts.2' })];

    return (
        <Stack dir="auto" sx={styles.profile} className="profile" >
            <Box component="img" sx={styles.profile__image} className="profile__image" src={profileImage} alt="KodKnight Profile's Image"/>
            <Console texts={texts}/>
        </Stack>
    )
}

const Console=({texts})=>{
    useEffect(() => {
        terminateConsole()
        consoleText(texts, 'profile__text')
    }, [texts])
    
    return (
        <Stack sx={styles.profile__console}  >
            <Typography sx={styles.profile__text} id="profile__text" className="profile__text" variant="span"></Typography>
            <Typography id="profile__console" className="profile__console" variant="span">&#95;</Typography>
        </Stack>
            )
}

export default Profile;
//export default injectIntl(Profile);