import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react'
import Styles from "./ServiceStyles.js";
import Zoom from '@mui/material/Zoom';

export default function Service({service,odd}) {
    let styles=Styles(odd)
    return (
        <Stack sx={styles.service} flexDirection="column" >
            <Box component="img" sx={styles.service__image} alt={service.alt} src={service.image} className='animation'/>
            <Stack sx={styles.service__info} className='right-animation'>
                <Typography variant="h6" sx={styles.service__title}>
                    {service.title}
                </Typography>
                <Typography variant="p" sx={styles.service__paragraph}>
                    {service.paragraph}
                </Typography>
                {service.list&&<Stack sx={styles.service__options}>
                <Typography variant="h6" sx={styles.service__option_name}>
                    {service.list.title}
                </Typography>
                <Stack sx={styles.service__option} >
                    {service?.list.items.map((item)=>(
                        <Tooltip title={item.name} TransitionComponent={Zoom} arrow>
                            <Box component="img" sx={styles.service__option_image} src={item.image} />
                        </Tooltip>
                    ))}
                </Stack>
                </Stack>}
            </Stack>
        </Stack>
    )
}


