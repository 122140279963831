import { Avatar, Box, Stack, Typography } from "@mui/material";

//import {ReactComponent as Logo} from "../../assets/images/logo.png";
import Logo from "../../assets/images/white-logo.webp";
import { ReactComponent as Facebook } from "../../assets/images/facebook.svg";
import { ReactComponent as Youtube } from "../../assets/images/youtube.svg";
import { ReactComponent as WhatsApp } from "../../assets/images/whatsapp.svg";
import Brightness from '@mui/icons-material/BrightnessLow';
import Darknesss from '@mui/icons-material/Brightness4';
import BrightnessAutoRoundedIcon from '@mui/icons-material/BrightnessAutoRounded';
import config from "../../config.json";
import styles from "./FooterStyles.js";
import { BrightnessActions } from "../../reducers/BrightnessReducer";
import { useContext, useState } from "react";
import { LocaleContext, ModeContext } from "../../App";
import { LocaleActions } from "../../reducers/LocaleReducer";
import { FormattedMessage } from "react-intl";
import { LOCALES } from "../../i18n";
import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import FBPixel from "../../analytics/FBPixel.js";
// import { WhatsApp } from "@mui/icons-material";



const Footer = (props) => {
    const modeContext = useContext(ModeContext)

    const handleBrightness = () => {
        modeContext.brightnessDispatch({ "type": BrightnessActions.UPDATE_BRIGHTNESS })
        if (process.env.NODE_ENV === "production") {

            ReactGA.event({
                category: "brightness",
                action: "click",
                label: modeContext.brightness.mode, // optional
                value: modeContext.brightness.preferable,
                /*nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image*/
            });
        }
    }

    const handleSocialMedia = (e) => {

        if (process.env.NODE_ENV === "production") {

            ReactGA.event({
                category: "social media",
                action: "click",
                label: e.currentTarget.getAttribute('data-alt'), // optional
                /*nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image*/
            });
            FBPixel.track("Contact",{type:e.target.getAttribute("data-alt")})

        }
        window.open(e.currentTarget.getAttribute('data-link'))
    }

    return (
        <Box ref={props.ref} sx={styles.footer} backgroundColor="primary.main">
            <Link aria-label="home" to="/" onClick={() => {
                document.getElementById("home").scrollIntoView({ behavior: "smooth" })
            }}>
                <Box component="img" alt="KodKnight's logo" src={Logo} sx={styles.footer__logo} />
            </Link>

            <Typography sx={styles.footer__copy_right} variant="span">
                {`${new Date().getFullYear()} ${config.name} ${String.fromCharCode(169)}`} <FormattedMessage id="copyright" />
            </Typography>
            <Stack sx={styles.footer__contacts} >
                <Avatar sx={styles.footer__contact} data-alt="facebook" data-link={process.env.REACT_APP_FACEBOOK_LINK} onClick={handleSocialMedia}>
                    <Facebook />
                </Avatar>
                <Avatar sx={styles.footer__contact} data-alt="twitter" data-link={process.env.REACT_APP_YOUTUBE_LINK} onClick={handleSocialMedia}>
                    <Youtube />
                </Avatar>
                <Avatar sx={styles.footer__contact} data-alt="linked in" data-link={process.env.REACT_APP_WHATSAPP_LINK} onClick={handleSocialMedia}>
                    <WhatsApp />
                </Avatar>
                <Avatar sx={styles.footer__contact} alt="brightness" onClick={handleBrightness}>
                    {renderBrightnessAvatars(modeContext.brightness)}
                </Avatar>
                <LanguagePicker />

            </Stack>
        </Box>
    )
}
const renderBrightnessAvatars = (brigthness) => {
    switch (brigthness.mode) {
        case "light":
            return <Brightness />;
            break;
        case "dark":
            return <Darknesss />;
            break;
        default:
            return <BrightnessAutoRoundedIcon />;
            break;
    }
}
const LanguagePicker = () => {
    const localeContext = useContext(LocaleContext)
    const [isLanguageOptionVisible, setLanguageOptionVisible] = useState(false)

    const toggleLanguageOptions = (e) => {
        setLanguageOptionVisible(!isLanguageOptionVisible)
    }
    const handleLanguageOptionClick = (e) => {
        localeContext.localeDispatch({ "type": LocaleActions.UPDATE_LOCALE, "lang": e.target.textContent })
        setLanguageOptionVisible(false);
        if (process.env.NODE_ENV === "production") {

            ReactGA.event({
                category: "language",
                action: "click",
                label: localeContext.locale.language, // optional
                /*nonInteraction: true, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image*/
            });
        }

    }
    return (
        <Stack sx={{ ...styles.footer__language, ...(isLanguageOptionVisible ? styles.footer__language_options_visible : {}) }} >
            <Avatar sx={styles.footer__contact} onClick={toggleLanguageOptions}>
                {localeContext.locale.language}
            </Avatar>
            {
                isLanguageOptionVisible &&
                <Stack sx={styles.footer__language_options} >
                    {Object.values(LOCALES).filter(locale => locale.language !== localeContext.locale.language).map((key, val) => (
                        <Avatar sx={styles.footer__contact} onClick={handleLanguageOptionClick}>
                            {key.language}
                        </Avatar>
                    )
                    )}
                </Stack>
            }

        </Stack>)
}
export default Footer;
